import {
  processBusy,
  processComplete,
  processError,
  processRemove,
  ProcessState,
  Process
} from '@cmi/store/process';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

export interface ProcessEntityState extends EntityState<Process> {}

const processAdapter = createEntityAdapter<Process>({ selectId: (state) => state.key });

export const processInitialState = processAdapter.getInitialState();

export const processReducer = createReducer(
  processInitialState,
  on(processBusy, (state, prop) =>
    processAdapter.upsertOne(
      { key: prop.key, processState: ProcessState.Busy, busyMessage: prop.busyMessage },
      state
    )
  ),
  on(processComplete, (state, prop) =>
    processAdapter.upsertOne(
      { key: prop.key, processState: ProcessState.Complete, busyMessage: undefined },
      state
    )
  ),
  on(processError, (state, prop) =>
    processAdapter.upsertOne(
      { key: prop.key, processState: ProcessState.Error, busyMessage: undefined },
      state
    )
  ),
  on(processRemove, (state, prop) => processAdapter.removeOne(prop.key, state))
);

export const { selectEntities } = processAdapter.getSelectors();
