import { createAction, props } from '@ngrx/store';

const PREFIX = '[Process]';

export const processBusy = createAction(
  `${PREFIX} Busy`,
  props<{ key: string; busyMessage?: string }>()
);
export const processComplete = createAction(
  `${PREFIX} Complete`,
  props<{ key: string; message?: string; htmlMessage?: boolean }>()
);
export const processError = createAction(
  `${PREFIX}  Error`,
  props<{ key: string; message: string; htmlMessage?: boolean; error?: Error }>()
);
export const processRemove = createAction(`${PREFIX}  Remove`, props<{ key: string }>());
