import { ToastMessageType } from '@ajgre/toolkit';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { processComplete, processError } from '@cmi/store/process';
import { toastMessage } from '@cmi/store/toast';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class ProcessEffects {
  constructor(private actions$: Actions) {}

  processComplete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processComplete),
      switchMap((action) =>
        action.message
          ? of(
              toastMessage({
                toastMessage: {
                  messageType: ToastMessageType.Success,
                  header: 'Success',
                  message: action.message,
                  htmlMessage: action.htmlMessage ?? false
                }
              })
            )
          : EMPTY
      )
    )
  );

  processError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processError),
      switchMap((action) => {
        //
        // NOTE: The action.error object could be handled here and passed onto a monitoring system (App Insights or Google Analytics etc.)*/
        //
        if (action.error && environment.loggingEnabled) {
          // eslint-disable-next-line no-console
          console.error(action.error);
        }

        // TODO: Problem is toast display 403 error when it shouldn't.
        // because there is a 403 page for that.
        //This fix is temporary. The cause of this issue is http call calling before the guard prevents it.
        const errorCode = (action.error as HttpErrorResponse)?.status;

        return errorCode && errorCode === 403
          ? EMPTY
          : of(
              toastMessage({
                toastMessage: {
                  messageType: ToastMessageType.Error,
                  header: 'Error',
                  message: (action?.error as HttpErrorResponse)?.error ?? action.message,
                  htmlMessage: (action?.error as HttpErrorResponse)?.error
                    ? false
                    : action.htmlMessage ?? false
                }
              })
            );
      })
    )
  );
}
