import { LogLevel } from '@azure/msal-browser';

const protectedResourceMap = new Map([
  ['https://graph.microsoft.com/v1.0/me', ['user.read']],
  [
    'https://authorizationapiqa.gallagherre.com/api/',
    ['api://5d7940cb-fedd-4c54-bd59-22cb9c167668/user_impersonation']
  ],
  [
    'https://cmiapiqa.gallagherre.com/api/',
    ['api://69429900-928b-4b45-a5d2-1f7e0e5479fb/accessuser']
  ]
]);

const b2cProtectedResourceMap = new Map([
  [
    'https://authorizationapiqa.gallagherre.com/api/',
    ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']
  ],
  [
    'https://cmiapiqa.gallagherre.com/api/',
    ['https://ajgb2c.onmicrosoft.com/820ba9db-2faa-4b06-a2d3-1e35c6cf605a/user_impersonation']
  ]
]);

export const environment = {
  environment: 'qa',
  production: true,
  loggingEnabled: true,
  logLevel: LogLevel.Verbose,

  clientId: 'ca07a20f-fe21-4e51-9a86-cf0fd46f69b9',
  authority: 'https://login.microsoftonline.com/6cacd170-f897-4b19-ac58-46a23307b80a',
  customScopes: [],
  protectedResourceMap,
  applicationName: 'gallagherre-cmi',
  rolesCacheSeconds: 10,
  authenticationCacheSeconds: 10,

  b2cClientId: 'fb4bac7d-702d-46cc-8530-c2ee5f5ba212',
  b2cAuthority: 'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_cmi_si',
  b2cResetPasswordAuthority:
    'https://ajgb2c.b2clogin.com/ajgb2c.onmicrosoft.com/B2C_1_gre_b2c_cmi_pass_reset/',
  b2cKnownAuthorities: ['ajgb2c.b2clogin.com'],
  b2cCustomScopes: [],
  b2cProtectedResourceMap,

  redirectUri: 'https://cmiqa.gallagherre.com/login',
  postLogoutRedirectUri: 'https://cmiqa.gallagherre.com/',
  authorisationUrl: 'https://authorizationapiqa.gallagherre.com/api/',
  cmiApiUri: 'https://cmiapiqa.gallagherre.com'
};
