import {
  ProcessState,
  PROCESS_FEATURE_KEY,
  ProcessEntityState,
  selectEntities
} from '@cmi/store/process';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const selectProcessEntityState = createFeatureSelector<ProcessEntityState>(PROCESS_FEATURE_KEY);

export const selectProcessEntities = createSelector(selectProcessEntityState, (state) =>
  selectEntities(state)
);

export const selectProcessState = (key: string) =>
  createSelector(selectProcessEntities, (entities) => entities[key]?.processState);

export const selectProcessBusy = (key: string) =>
  createSelector(
    selectProcessEntities,
    (entities) => entities[key]?.processState === ProcessState.Busy
  );
export const selectProcessBusyMessage = (key: string) =>
  createSelector(selectProcessEntities, (entities) => entities[key]?.busyMessage);
export const selectProcessError = (key: string) =>
  createSelector(
    selectProcessEntities,
    (entities) => entities[key]?.processState === ProcessState.Error
  );
